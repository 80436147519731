import { PipeTransform, Pipe } from '@angular/core';
import * as d3 from 'd3';
import { RoutingService } from '../service/routing.service';

/**
 * This pipe define the sentiment color
 */

@Pipe({ name: 'colorVolume' })
export class ColorVolumePipe implements PipeTransform {
  constructor(public routing: RoutingService) {}
  transform(value: any): string {
    if (value === 'nf' || value === 'null' || value === null || value === -1) {
      return '#a3a3a3';
    }
    if (value === 'pos' || value === 'buy') {
      value = 1;
    }
    if (value === 'neu') {
      value = 0.5;
    }
    if (value === 'neg' || value === 'sell') {
      value = 0;
    }
    if (this.routing.theme === 'light') {
      const colorScale = d3
        .scaleLinear<string>()
        .domain([0, 0.44, 0.45, 0.5, 0.55, 0.56, 1])
        .range([
          '#f26e6e',
          '#fededb',
          '#f2f2f2',
          '#f2f2f2',
          '#f2f2f2',
          '#d2f6ea',
          '#60e9d0',
        ]);
      return colorScale(value.toFixed(2)).toString();
    } else {
      const colorScale = d3
        .scaleLinear<string>()
        .domain([0, 0.44, 0.45, 0.5, 0.55, 0.56, 1])
        .range([
          '#f26e6e',
          '#fededb',
          '#f2f2f2',
          '#f2f2f2',
          '#f2f2f2',
          '#d2f6ea',
          '#60e9d0',
        ]);
      return colorScale(value.toFixed(2)).toString();
    }
  }
}
