/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { AuthService } from 'src/app/service/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FilterListService {
  constructor(
    private yukkApi: YukkApi,
    public auth: AuthService,
  ) {}
  private filterEvents = [];
  private filterSubEvents = {};
  private allSubEvents = [];
  private eventsList: { events: any[]; subEvents: { [key: string]: any } } = {
    events: [],
    subEvents: {},
  };

  getFilterListItem(): Observable<any> {
    return this.yukkApi.getFiltersList().pipe(
      map((result: any) => {
        const languages = result.filter((element) => element.filter === 'lang');
        const documentCategories = result.filter(
          (element) => element.filter === 'document_categories',
        );

        const sourceRanks = result.filter(
          (element) => element.filter === 'source_ranks',
        );
        const sourceContinents = result.filter(
          (element) => element.filter === 'source_continents',
        );
        const sourceCountries = result.filter(
          (element) => element.filter === 'source_countries',
        );
        const sourcePanels = result.filter(
          (element) => element.filter === 'source_panels',
        );
        const events = result.filter((element) => element.filter === 'events');
        if (events.length === 1) {
          this.filterEvents = [];
          this.eventsList = {
            events: [],
            subEvents: {},
          };

          events[0].values.forEach((superEvent) => {
            superEvent.children.forEach((event) => {
              this.filterEvents.push({
                label: event.entity.name,
                value: event.entity.alpha_id,
                selected: false,
              });
              this.eventsList.events.push({
                label: event.entity.name,
                value: event.entity.alpha_id,
              });
              this.filterSubEvents[event.entity.alpha_id] = [];
              this.eventsList.subEvents[event.entity.alpha_id] = [];
              event.children.forEach((subevent) => {
                this.filterSubEvents[event.entity.alpha_id].push({
                  label: subevent.entity.name,
                  value: subevent.entity.alpha_id,
                  selected: false,
                });
                this.eventsList.subEvents[event.entity.alpha_id].push({
                  label: subevent.entity.name,
                  value: subevent.entity.alpha_id,
                });
                this.allSubEvents.push(subevent);
              });
            });
          });
        }
        return {
          languages,
          documentCategories,
          sourceRanks,
          sourceContinents,
          sourceCountries,
          sourcePanels,
          events,
          eventList: this.eventsList,
          filterEvents: this.filterEvents,
          filterSubEvents: this.filterSubEvents,
          allSubEvents: this.allSubEvents,
        };
      }),
    );
  }

  getEventList(): Observable<{
    events: any[];
    subEvents: { [key: string]: any };
  }> {
    return this.getFilterListItem().pipe(
      map((data) => data.eventList), // Extract only the eventList
    );
  }
}
