import { Pipe, PipeTransform } from '@angular/core';
import * as d3 from 'd3';
import { RoutingService } from '../service/routing.service';

/**
 * This pipe defines the score color scheme 2
 */

@Pipe({ name: 'colorScore2' })
export class ColorScore2Pipe implements PipeTransform {
  constructor(public routing: RoutingService) {}
  transform(value: any): string {
    if (value) {
      let colors = [];
      if (this.routing.theme === 'light') {
        // colors = ['#540c0c', '#a71717', '#f6d500', '#009e00', '#80cf80'];
        colors = ['#a71717', '#f6d500', '#009e00'];
      } else {
        // colors = ['#6f000d', '#dd001a', '#f6d500', '#3dac00', '#9fd680'];
        colors = ['#dd001a', '#f6d500', '#3dac00'];
      }
      const colorScale = d3
        .scaleLinear()
        // .domain([0, 4, 5, 6, 10])
        .domain([0, 5, 10])
        .range(colors);
      return colorScale(value).toString();
    } else {
      return 'rgb(120,120,120)';
    }
  }
}
