<div class="alerts-main">

    <div class="alerts-list" [class.theme-light]="routing.theme === 'light'">

        <ng-container *ngIf="!loadingAlerts">
            <mat-tab-group [selectedIndex]="selectedTabIndex"
                           (selectedIndexChange)="onTabSelection($event)">
                <mat-tab [class.theme-light]="routing.theme === 'light'" *ngFor="let tab of tabNames; let index = index" [label]="tab">
                    <div class="alerts-list-header">
                        <!--<div class="alerts-list-title">Alerts</div>-->
                        <div class="alerts-list-info" *ngIf="tab === 'Score Alerts'">
                            Setup alerts to be notified in real-time whenever major trends happen.
                        </div>
                        <div class="alerts-list-info" *ngIf="tab === 'Event Alerts'">
                            Setup alerts to be notified on a daily basis whenever major trends happen.
                        </div>
                    </div>
                    <div class="alerts-list-container">
                        <div class="list-container-contents">
                            <div class="custom-alerts">
                                <ng-container *ngIf="(alerts.length > 0)">
                                    <div class="option" [class.active]="alert.active" *ngFor="let alert of alerts | filterBy: ['alert_category']: ((selectedTabIndex === 0) ? 'scores' : 'events')" (click)="selectAlert(alert)">
                                        <div class="alert" [class.current]="currentAlert.uid === alert.uid">{{alert.name}}</div>
                                        <div class="alert-slide-toggle">
                                            <p>{{alert.active ? 'Active' : 'Inactive'}}</p>
                                            <mat-slide-toggle *ngIf="alert.active" [checked]="true" (change)="onSlideToggle($event.checked, alert)"></mat-slide-toggle>
                                            <mat-slide-toggle *ngIf="!alert.active" [checked]="false" (change)="onSlideToggle($event.checked, alert)"></mat-slide-toggle>
                                            <textarea matInput hidden></textarea>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="list-container-actions">
                            <div class="option" (click)="createAlert()">
                                <mat-icon>add</mat-icon>
                                <div class="alert" [class.current]="currentAlert && (currentAlert.uid ===  null)">Create Alert</div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </ng-container>

        <div class="loading-alerts" *ngIf="loadingAlerts">
            <mat-spinner [diameter]="50"></mat-spinner>
        </div>

    </div>

    <div class="alerts-settings">

        <ng-container *ngIf="!loadingAlerts">
            <div class="alerts-settings-container">
                <div class="settings-container-contents">

                    <div class="alerts-section-header">General alert information</div>

                    <mat-form-field appearance="outline" class="field-type">
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="Create alert's name *" required [(ngModel)]="currentAlert.name">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="field-type">
                      <mat-label>Additional Recipients from {{domain}}</mat-label>
                      <mat-chip-list #chipListAlertRecipients aria-label="Email selection">
                        <mat-chip *ngFor="let item of emailList" [selectable]="selectable" [removable]="removable" (removed)="remove(item)">
                          {{item}}
                          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input matInput [matChipInputFor]="chipListAlertRecipients" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                      </mat-chip-list>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="field-type" *ngIf="selectedTabIndex === 0">
                        <mat-label>Schedule</mat-label>
                        <mat-select #selectorAlertSchedule [(ngModel)]="currentAlert.schedule" required>
                            <mat-option value="1d">Check alert's triggers daily</mat-option>
                            <mat-option value="1w">Check alert's triggers weekly</mat-option>
                            <mat-option value="30d">Check alert's triggers monthly</mat-option>
                            <mat-option value="once">Send alert only once and deactivate</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="field-type" *ngIf="selectedTabIndex === 0">
                        <mat-label>Channel</mat-label>
                        <mat-select #selectorAlertChannel [(ngModel)]="currentAlert.layout" required (selectionChange)="onChannelChange()">
                            <mat-option value="digest">Digest - Email</mat-option>
                            <mat-option value="explainable_digest">Detailed Digest - Email</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <p>Alert Type *</p>
                    <mat-radio-group #selectorAlertType [(ngModel)]="currentAlert.alert_type" (change)="optSelect2()" required>
                        <mat-radio-button [style.border-color]="(routing.theme === 'light') ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.3)'" value="entity">Entity</mat-radio-button>
                        <mat-radio-button [style.border-color]="(routing.theme === 'light') ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.3)'" value="portfolio">Portfolio</mat-radio-button>
                        <!--<mat-option value="search_query">Query</mat-option>
                        <mat-option value="search_query_group">Collection</mat-option>-->
                    </mat-radio-group>

                    <p>Choose the object to set-up an alert for</p>

                    <mat-form-field appearance="outline" class="field-search" *ngIf="currentAlert.alert_type === 'portfolio'">
                        <mat-label>Name</mat-label>
                        <mat-select  #selectorAlertObject [(ngModel)]="currentAlert.object_id" (selectionChange)="optSelect3($event, 'portfolio')" required>
                            <mat-option *ngFor="let option of folio$ | async | orderBy:'name'
" [value]="option.uid" [disabled]="option.content.length === 0">{{option.name}}{{(option.content.length === 0) ? ' (Portfolio is empty)' : ''}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="field-search" *ngIf="currentAlert.alert_type === 'entity'">
                        <mat-label>Search</mat-label>
                        <input #inputAlertObject matInput type="text" [matAutocomplete]="menuSearch" [formControl]="searchFormControl" required>
                        <!--<div class="iresult" *ngIf="!loading && (item$|async)?.entity_prefix_search.hits.length>1">
                          {{(item$|async)?.entity_prefix_search.hits.length}} results
                        </div>-->
                        <mat-autocomplete #menuSearch="matAutocomplete" (optionSelected)="optSelect($event.option.value)" [autoActiveFirstOption]="true" class="searchLetter search-panel" panelWidth="auto">
                            <div *ngIf="items$|async as search">
                                <div *ngFor="let items of search.hits | groupBy:'entity.type' | orderByType | pairs ">
                                    <mat-optgroup>
                                        <div class="optgroup-search">
                                            <div class="indexo-left">{{items[0] | nicetxt}}</div>
                                            <div class="indexo-right">
                                                <div class="optgroup-column column-1">Country</div>
                                                <div class="optgroup-column"># Articles</div>
                                            </div>
                                        </div>
                                        <ng-container *ngFor="let option of items[1] | orderBy: '-count'">
                                          <mat-option *ngIf="!option.entity.hasParent" [value]="option">
                                            <div class="search-indexo">
                                              <div class="indexo-left">
                                                <span>{{option.entity.name}}</span>
                                                <span class="match">{{(option.entity.matched_form) ? option.entity.matched_form : ''}}</span>
                                              </div>
                                              <div class="indexo-right">
                                                <div class="optgroup-column column-1">{{(option.entity && option.entity.country && option.entity.country.alpha_id) ? option.entity.country.alpha_id.toUpperCase() : '\u2014'}}</div>
                                                <div class="optgroup-column">{{numberFormat(option.count)}}</div>
                                              </div>
                                            </div>
                                          </mat-option>
                                          <mat-option *ngFor="let optionChild of option.entity.children | orderBy: '-count'" [value]="optionChild">
                                            <div class="search-indexo">
                                              <div class="indexo-left">
                                                <span class="subsidiary">{{optionChild.entity.name}}</span>
                                                <span class="match" *ngIf="iMatch(optionChild.entity)">{{(optionChild.entity.matched_form) ? optionChild.entity.matched_form : ''}}</span>
                                              </div>
                                              <div class="indexo-right">
                                                <div class="optgroup-column column-1">{{(optionChild.entity && optionChild.entity.country && optionChild.entity.country.alpha_id) ? optionChild.entity.country.alpha_id.toUpperCase() : '\u2014'}}</div>
                                                <div class="optgroup-column">{{numberFormat(optionChild.count)}}</div>
                                              </div>
                                            </div>
                                          </mat-option>
                                        </ng-container>
                                    </mat-optgroup>
                                </div>
                            </div>
                        </mat-autocomplete>
                        <mat-spinner [diameter]="15" *ngIf="loading"></mat-spinner>
                    </mat-form-field>


                    <ng-container *ngIf="selectedTabIndex === 0">

                        <p *ngIf="currentAlert.alert_type !== 'portfolio'">Set Alert Triggers</p>
                        <p *ngIf="currentAlert.alert_type === 'portfolio'">Set Alert Trigger</p>

                        <div class="alerts-group">
                            <div class="alerts-group-cont">
                                <div class="alerts-group-main" *ngIf="currentAlert.triggers?.length">
                                    <div class="alerts-group-item" *ngFor="let trigger of currentAlert.triggers; index as index; last as last;">
                                        <div class="subalert-main">


                                          <div class="subalert-threshold">

                                            <mat-form-field class="field-subalert type">
                                              <mat-label>Choose Score</mat-label>
                                              <mat-select #selectorTriggerScore [(ngModel)]="trigger.score_type" (selectionChange)="onScoreChange($event, index)" required>
                                                <mat-option *ngFor="let score of scoreTypes" [value]="score.value" [disabled]="(currentAlert.layout === 'explainable_digest') && (score.value === 'sentiment')">{{score.label}}</mat-option>
                                              </mat-select>
                                            </mat-form-field>

                                            <mat-form-field class="field-subalert setting">
                                              <mat-label></mat-label>
                                              <mat-select #selectorTriggerThresholdSetting [(ngModel)]="trigger.threshold_setting" required>
                                                <!--<mat-option *ngIf="routing.isDev() && (currentAlert.schedule === 'once')" value="above_equal">&#8805;{{(routing.isDev() ? '&nbsp; above_equal' : '')}}</mat-option>
                                                <mat-option *ngIf="routing.isDev() && (currentAlert.schedule === 'once')" value="below_equal">&#8804;{{(routing.isDev() ? '&nbsp; below_equal' : '')}}</mat-option>-->
                                                <ng-container *ngIf="!trigger.isDeltaAlert">
                                                  <mat-option value="cross_above">&#8805;</mat-option>
                                                  <mat-option value="cross_below">&#8804;</mat-option>
                                                  <mat-option *ngFor="let setting of thresholdSettingTypes" [value]="setting.value">{{setting.label}}</mat-option>
                                                </ng-container>
                                                <ng-container *ngIf="trigger.isDeltaAlert">
                                                  <mat-option value=">=">&#8805;</mat-option>
                                                  <mat-option value="<=">&#8804;</mat-option>
                                                </ng-container>
                                              </mat-select>
                                            </mat-form-field>

                                            <mat-form-field class="field-subalert value" *ngIf="(trigger.score_type !== 'sentiment')">
                                              <mat-label>Value...</mat-label>
                                              <input #inputTriggerThreshold type="number"
                                                     maxlength="2"
                                                     min="0"
                                                     max="10"
                                                     required
                                                     matInput
                                                     [(ngModel)]="trigger.threshold"
                                                     (change)= "trigger.threshold < 0 ? trigger.threshold = 0 : trigger.threshold; trigger.threshold > 10 ? trigger.threshold = 0 : trigger.threshold;"
                                                     name="threshold">
                                              <mat-error>Please enter a value between 0 and 10</mat-error>
                                            </mat-form-field>

                                            <mat-form-field class="field-subalert value" *ngIf="(trigger.score_type === 'sentiment')">
                                              <mat-label>Value...</mat-label>
                                              <input #inputTriggerThreshold type="number"
                                                     maxlength="3"
                                                     min="0"
                                                     max="100"
                                                     required
                                                     matInput
                                                     [(ngModel)]="trigger.threshold"
                                                     (change)= "trigger.threshold < 0 ? trigger.threshold = 0 : trigger.threshold; trigger.threshold > 100 ? trigger.threshold = 0 : trigger.threshold;"
                                                     name="threshold">
                                              <mat-error>Please enter a value between 0 and 100</mat-error>
                                            </mat-form-field>

                                            <div class="btndel" (click)="delTrigger(index)" *ngIf="(currentAlert.triggers.length > 1)">
                                              <mat-icon>remove_circle</mat-icon>
                                            </div>

                                          </div>

                                          <div class="subalert-delta-line">

                                            <mat-slide-toggle class="delta-alert-toggle" *ngIf="trigger.isDeltaAlert" [checked]="true" (change)="onDeltaSlideToggle($event.checked, trigger)" [disabled]="!trigger.score_type"></mat-slide-toggle>
                                            <mat-slide-toggle class="delta-alert-toggle" *ngIf="!trigger.isDeltaAlert" [checked]="false" (change)="onDeltaSlideToggle($event.checked, trigger)" [disabled]="!trigger.score_type"></mat-slide-toggle>
                                            <span class="delta-alert-header">Associate a Delta Alert</span>
                                            <mat-icon matTooltip="Delta Alert notifies when the score changes by a set amount, based on the threshold value defined above">info</mat-icon>

                                          </div>


                                          <ng-container *ngIf="trigger.isDeltaAlert">

                                            <div class="subalert-delta-line">

                                              <span>Notify me when the score</span>

                                              <mat-form-field class="field-delta-alert direction">
                                                <mat-select [(ngModel)]="trigger.additional_params.z_score_direction" required>
                                                  <mat-option [value]="'pos'">increases by</mat-option>
                                                  <mat-option [value]="'neg'">decreases by</mat-option>
                                                  <mat-option [value]="'both'">changes by</mat-option>
                                                </mat-select>
                                              </mat-form-field>

                                              <mat-form-field class="field-delta-alert value" *ngIf="(trigger.score_type !== 'sentiment')">
                                                <mat-label>Value...</mat-label>
                                                <input type="number"
                                                       maxlength="2"
                                                       min="0"
                                                       max="1.5"
                                                       required
                                                       matInput
                                                       [(ngModel)]="trigger.additional_params.z_score_threshold"
                                                       (change)= "trigger.additional_params.z_score_threshold < 0 ? trigger.additional_params.z_score_threshold = 0 : trigger.additional_params.z_score_threshold; trigger.additional_params.z_score_threshold > 1.5 ? trigger.additional_params.z_score_threshold = 0 : trigger.additional_params.z_score_threshold;"
                                                       name="threshold">
                                                <mat-error>Please enter a value between 0 and 1.5</mat-error>
                                              </mat-form-field>

                                              <mat-form-field class="field-delta-alert value" *ngIf="(trigger.score_type === 'sentiment')">
                                                <mat-label>Value...</mat-label>
                                                <input type="number"
                                                       maxlength="3"
                                                       min="0"
                                                       max="15"
                                                       required
                                                       matInput
                                                       [(ngModel)]="trigger.additional_params.z_score_threshold"
                                                       (change)= "trigger.additional_params.z_score_threshold < 0 ? trigger.additional_params.z_score_threshold = 0 : trigger.additional_params.z_score_threshold; trigger.additional_params.z_score_threshold > 15 ? trigger.additional_params.z_score_threshold = 0 : trigger.additional_params.z_score_threshold;"
                                                       name="threshold">
                                                <mat-error>Please enter a value between 0 and 15</mat-error>
                                              </mat-form-field>

                                            </div>

                                            <div class="subalert-delta-line">
                                              <span class="delta-alert-header">Alert for consecutive thresholds</span>
                                              <mat-icon matTooltip="If the delta threshold is met on consecutive days, select your preffered alert frequency">info</mat-icon>
                                            </div>

                                            <div class="subalert-delta-line">
                                              <mat-radio-group class="group-delta-frequency" [(ngModel)]="trigger.additional_params.plateau_period">
                                                <mat-radio-button value="0">Once</mat-radio-button>
                                                <mat-radio-button value="1">Daily</mat-radio-button>
                                                <mat-radio-button value="selectedDeltaPeriod">
                                                  Every
                                                  <mat-select [(ngModel)]="selectedDeltaPeriod" [disabled]="[0, 1].includes(trigger.additional_params.plateau_period)">
                                                    <mat-option *ngFor="let period of deltaPeriods" [value]="period">{{period}}</mat-option>
                                                  </mat-select>
                                                  days
                                                </mat-radio-button>
                                              </mat-radio-group>
                                            </div>

                                          </ng-container>


                                          <div class="subalert-delta-container"></div>




                                        </div>

                                    </div>
                                </div>

                                <div class="btnflex group" *ngIf="(currentAlert.alert_type !== 'portfolio') && currentAlert.triggers?.length">
                                    <button mat-button (click)="addTrigger()">
                                        <mat-icon>library_add</mat-icon>
                                        <span>TRIGGER</span>
                                    </button>
                                </div>

                            </div>

                        </div>

                    </ng-container>


                    <ng-container *ngIf="selectedTabIndex === 1">

                        <div class="alerts-section-header">Events</div>

                        <p>Filter Events by Score</p>

                        <mat-form-field appearance="outline" (click)="eventsScoreSelectionAlert()">
                            <mat-label>Name</mat-label>
                            <mat-select #selectorEventsScore [(ngModel)]="currentAlert.score_type" (selectionChange)="onEventsScoreChange($event)" required>
                                <mat-option *ngFor="let score of scoreEventsTypes" [value]="score.value">{{score.label}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <ng-container *ngIf="currentAlert.score_type">

                            <p>Add/Modify {{showEventsFilterLabel()}} Events</p>

                            <mat-form-field floatLabel="never" appearance="outline" class="field-type">
                                <mat-label>
                                    <mat-icon>search</mat-icon>
                                    Click to scroll or type... *
                                </mat-label>
                                <input #inputEvents type="text" placeholder="Select Events" aria-label="Select Events" matInput [matAutocomplete]="auto" [formControl]="eventsControl">
                            </mat-form-field>

                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnEvents" (opened)="onAutocompleteOpen($event)" (closed)="onAutocompleteClose($event)">

                                <!--<mat-optgroup *ngFor="let group of filteredEventCategories | async | orderBy: 'name' | filterBy: ['id']: scoreCategories: strictFiltering">

                                    <div>
                                        <mat-checkbox [checked]="group.selected" (change)="toggleEventGroupSelection(group, filteredEvents)" (click)="$event.stopPropagation()">
                                            {{ group.name }}
                                        </mat-checkbox>
                                    </div>

                                    <mat-option *ngFor="let event of filteredEvents | async | orderBy: 'name' | filterBy: ['category']: group.name | filterBy: ['id']: scoreEvents: strictFiltering" [value]="selectedEvents" class="score-event-option">
                                        <div>
                                            <mat-checkbox [checked]="event.selected" (change)="toggleEventSelection(event); unselectGroup(group);" (click)="$event.stopPropagation()">
                                                {{ event.name }}
                                            </mat-checkbox>
                                        </div>
                                    </mat-option>

                                </mat-optgroup>-->

                                <div class="autocomplete-list">

                                    <mat-optgroup *ngFor="let group of filteredEventCategories | async | orderBy: 'name' | filterBy: ['id']: scoreCategories: strictFiltering">

                                        <div>
                                            <mat-checkbox [checked]="group.selected" (change)="toggleEventGroupSelection(group, filteredEvents)" (click)="$event.stopPropagation()">
                                                {{ group.name }}
                                            </mat-checkbox>
                                        </div>

                                        <mat-option *ngFor="let event of filteredEvents | async | orderBy: 'name' | filterBy: ['category']: group.name | filterBy: ['id']: scoreEvents: strictFiltering" [value]="selectedEvents" class="score-event-option">
                                            <div>
                                                <mat-checkbox [checked]="event.selected" (change)="toggleEventSelection(event); unselectGroup(group);" (click)="$event.stopPropagation()">
                                                    {{ event.name }}
                                                </mat-checkbox>
                                            </div>
                                        </mat-option>

                                    </mat-optgroup>

                                </div>

                                <div class="autocomplete-footer">
                                    <button mat-button (click)="clearEvents()">CLEAR</button>
                                    <button mat-button [class.button-active]="this.selectedEvents.length" (click)="selectEvents()">SELECT {{(selectedEvents && (selectedEvents.length > 0)) ? selectedEvents.length : ''}}</button>
                                </div>

                            </mat-autocomplete>

                            <p>Selected Events</p>

                            <mat-chip-list #chipList>
                                <mat-chip *ngFor="let event of selectedEvents" selectable="true" removable="true" (removed)="removeEvent(event)">
                                    {{event.name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>

                            <p>Set Impact Level</p>

                            <div class="alerts-events-impact" appDetectVisibility (isVisible)="markFieldAsSeen($event)">
                                <div>
                                    <p>Low Impact Events</p>
                                    <p>(more alerts)</p>
                                </div>
                                <mat-slider *ngIf="(user.roles?.includes('ADMIN') || user.groups?.includes('ADMIN'))" [(ngModel)]="currentAlert.threshold" (input)="currentAlert.threshold = $event.value" min="0" max="5" step="1" [displayWith]="sliderLabel"></mat-slider>
                                <mat-slider *ngIf="!(user.roles?.includes('ADMIN') || user.groups?.includes('ADMIN'))" [(ngModel)]="currentAlert.threshold" (input)="currentAlert.threshold = $event.value" min="1" max="5" step="1" [displayWith]="sliderLabel"></mat-slider>
                                <div>
                                    <p>High Impact Events</p>
                                    <p>(less alerts)</p>
                                </div>
                            </div>

                        </ng-container>

                    </ng-container>


                </div>
                <!--<div class="settings-container-actions" *ngIf="currentScoreType !== 'yukka'"></div>-->
            </div>
            <div class="alerts-settings-footer">
                <button class="delete" mat-button *ngIf="currentAlert.uid" (click)="deleteAlert()" [class.delete-warning]="isButtonActive" (mouseover)="isButtonActive = true" (mouseout)="isButtonActive = false">
                    <mat-icon>delete</mat-icon>
                    <span>DELETE</span>
                </button>
    
                <button class="save" [class.valid-alert]="isValidAlert(currentAlert, false) && ((fieldsSeen && (selectedTabIndex === 1)) || (selectedTabIndex === 0))" mat-button (click)="saveAlert(currentAlert.active, null)">
                    <mat-icon>save</mat-icon>
                    <span>SAVE</span>
                </button>
                <button *ngIf="routing.isDev() && (user.roles?.includes('ADMIN') || user.groups?.includes('ADMIN')) && currentAlert.uid" class="send" mat-button (click)="triggerAlert()" matTooltip="Trigger alert immediately">
                    <mat-icon>send</mat-icon>
                </button>
                <button *ngIf="routing.isDev() && (user.roles?.includes('ADMIN') || user.groups?.includes('ADMIN')) && currentAlert.uid" class="send" mat-button (click)="getAlertHistory()" matTooltip="Show alert's history in dev console">
                    <mat-icon>history</mat-icon>
                </button>
            </div>
        </ng-container>

    </div>

</div>
