import { Component, ViewEncapsulation } from '@angular/core';
import { ColorPipe } from 'src/app/pipe/color.pipe';
import { Chart } from 'angular-highcharts';
import * as moment from 'moment';
import * as d3 from 'd3';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { ConfigService } from 'src/app/service/config.service';
import { RoutingService } from 'src/app/service/routing.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

/**
 * The event participants view uses Highchart's bubble chart and bubble legend.
 */

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-news-participants',
  templateUrl: './news-participants.component.html',
  styleUrls: ['./news-participants.component.scss'],
})
export class NewsParticipantsComponent {
  options: any;
  chart: Chart;
  chartRef: any;
  inoptions = {
    color: '255,255,255',
    color1: '0,0,0',
  };
  colorPalette = ['#00fec2', '#18ebff', '#b4bdff', '#a351fe', '#fb00e6'];
  params: any;
  titolo: any;
  event_participants: any;
  series: any;
  loading: boolean;
  previousValue: any;
  previousValue2: any;
  nodata: boolean;
  theme: string;
  maxh = 120;
  bubmin = 8;
  bubmax: number;
  bubbleSize = 10;
  xDivider = 0;
  yDivider = 0;
  xMin = 0;
  xMax = 0;
  yMin = 0;
  yMax = 0;
  xType = 'ratio';
  yType = 'sentiment';
  activeTag: string;
  activeParticipant: string;
  countries = [];
  selectedCountry: string;
  sectors = [];
  selectedSector: string;

  constructor(
    private route: ActivatedRoute,
    private colorpipe: ColorPipe,
    private yukkApi: YukkApi,
    public routing: RoutingService,
    private router: Router,
    public auth: AuthService,
    public config: ConfigService,
  ) {
    this.theme = this.config.appConfig.routing.theme;

    this.route.queryParams.subscribe((params) => {
      this.params = params;
      this.activeTag = decodeURI(params.tag).split('|')[0];
      this.activeParticipant = decodeURI(params.participant).split('|')[0];
      this.bubmax = (window.innerWidth / 100) * 5;
      if (params.time === '7') {
        this.bubmax = (window.innerWidth / 100) * 20;
      }
      if (this.bubmax > 118) {
        this.bubmax = 118;
      }
      const reflowParams = ['reflow'];
      if (this.routing.reFresh(this.params, this.previousValue, reflowParams)) {
        if (this.chartRef) {
          setTimeout(() => {
            this.chartRef.source.value.reflow();
          }, 500);
        }
      }
      if (
        routing.reFresh(params, this.previousValue, [
          'type',
          'id',
          'time',
          'lang',
          'feed',
          'categories',
          'continents',
          'countries',
          'factuality',
          'temporality',
          'ranks',
          'panels',
          'event_ids',
          'period',
          'theme',
          'update',
          'updateAxis',
        ])
      ) {
        this.loading = true;
        this.nodata = false;
        this.event_participants = [];
        this.series = [];
        if (this.routing.theme === 'light') {
          this.inoptions.color = '0,0,0';
        }
        if (params.theme && params.theme === 'dark') {
          this.inoptions.color = '255,255,255';
        } else if (params.theme && params.theme === 'light') {
          this.inoptions.color = '0,0,0';
        }
        if (!(routing.isFolio() || routing.isHomepage())) {
          this.yukkApi.isentiment(this.params, true).subscribe((result) => {
            this.titolo = result;
          });
        }

        const requestsList = [];

        if (this.routing.isFolio() || routing.isHomepage()) {
          const content = this.auth.folio.content;
          requestsList.push(this.yukkApi.portfolio(this.params, content));
          const chunkSize = 100;
          for (let i = 0; i < content.length; i += chunkSize) {
            requestsList.push(
              this.yukkApi
                .relatedEvents(
                  Object.assign({}, this.params, {
                    body: content.slice(i, i + chunkSize),
                  }),
                  'sub_events_itemized',
                )
                .pipe(
                  catchError((error) => {
                    return of(
                      Array(
                        content.length - i < chunkSize
                          ? content.length - i
                          : chunkSize,
                      ).fill({ result: [] }),
                    );
                  }),
                ),
            );
          }
        } else {
          requestsList.push(
            this.yukkApi.relatedEntities(this.params, 'participants', 1000),
          );
        }

        forkJoin(requestsList).subscribe(
          (resultArray) => {
            let res = [];
            if (this.routing.isFolio() || routing.isHomepage()) {
              const portfolioEntities = resultArray[0];
              const res3 = resultArray.slice(1);
              // @ts-ignore
              const res2 = res3.flat();
              const filteredCompanies = [];
              res2.forEach((el, index) => {
                if (el.result && el.result.length > 0) {
                  let isParticipant = false;
                  el.result.forEach((event) => {
                    if (this.params && this.params.event_ids) {
                      if (
                        event.count > 0 &&
                        this.params.event_ids
                          .split(',')
                          .includes(event.event.id)
                      ) {
                        isParticipant = true;
                      }
                    } else {
                      if (event.count > 0) {
                        isParticipant = true;
                      }
                    }
                  });
                  if (isParticipant) {
                    filteredCompanies.push({
                      tag: 'm:' + this.auth.folio.content[index],
                    });
                  }
                }
              });
              // @ts-ignore
              const portfolioList = portfolioEntities.treemap.children.map(
                (element) => {
                  return {
                    entity: element.entity,
                    sentiment: {
                      count: element.count,
                      sentiment: element.sentiment,
                      sentiment_delta: element.sentiment_delta,
                      volume_ratio: element.volume_ratio,
                    },
                    tag: 'm:' + element.entity.compound_key,
                  };
                },
              );
              const portfolioParticipantsList = portfolioList.filter((item) => {
                return filteredCompanies
                  .map((element) => {
                    return element.tag;
                  })
                  .includes(item.tag);
              });
              res = portfolioParticipantsList;
            } else {
              // @ts-ignore
              res = resultArray[0];
            }
            if (res.length) {
              this.event_participants = res;
              let uniqueParticipants = [];
              if (this.routing.isQuery() && this.auth.iquery) {
                uniqueParticipants = this.event_participants[0];
              } else {
                uniqueParticipants = this.event_participants;
              }
              const countriesFilter = [];
              const sectorsFilter = [];
              uniqueParticipants.forEach((element) => {
                if (element.entity.description.Country) {
                  countriesFilter.push(
                    JSON.stringify({
                      label: element.entity.description.Country.name,
                      value: element.entity.description.Country.entity.alpha_id,
                    }),
                  );
                }
                if (element.entity.description.Sector) {
                  sectorsFilter.push(
                    JSON.stringify({
                      label: element.entity.description.Sector.name,
                      value: element.entity.description.Sector.entity.alpha_id,
                    }),
                  );
                }
              });
              const uniqueCountries = Array.from(new Set(countriesFilter));
              const uniqueSectors = Array.from(new Set(sectorsFilter));
              this.countries = uniqueCountries.map((item) => {
                return JSON.parse(item);
              });
              this.sectors = uniqueSectors.map((item) => {
                return JSON.parse(item);
              });
              if (this.routing.isQuery() && this.auth.iquery) {
                this.event_participants.shift();
              }

              this.setSeries();
              this.setExtremes();
              this.getOption();
              this.setAxisEvents();
              if (this.chartRef) {
                setTimeout(() => {
                  this.chartRef.source.value.reflow();
                }, 1000);
              }
            } else {
              this.loading = false;
              this.nodata = true;
            }
          },
          (error) => {
            this.loading = false;
            this.nodata = true;
          },
        );
      }
      this.previousValue = params;
    });
  }

  getPaletteColor() {
    const value = Math.random();
    const pickColor = d3
      .scaleLinear()
      .domain([0, 0.25, 0.5, 0.75, 1])
      .range(this.colorPalette as any);

    return pickColor(value);
  }

  onChange() {
    this.setSeries();
    this.setExtremes();
    this.getOption();
    this.setAxisEvents();
  }

  setAxisEvents() {
    this.chart.ref$.subscribe(() => {
      // @ts-ignore
      this.chart.ref.xAxis[0].axisGroup.element.childNodes.forEach((node) => {
        if (node.className.baseVal === 'highcharts-axis-title') {
          node.style.cursor = 'pointer';
          node.onclick = () => {
            if (this.xType === 'ratio') {
              this.xType = 'volume';
            } else if (this.xType === 'volume') {
              this.xType = 'ratio';
            }
            this.router.navigate([], {
              queryParams: {
                updateAxis: Math.random(),
              },
              queryParamsHandling: 'merge',
              replaceUrl: true,
            });
          };
        }
      });
      // @ts-ignore
      this.chart.ref.yAxis[0].axisGroup.element.childNodes.forEach((node) => {
        if (node.className.baseVal === 'highcharts-axis-title') {
          node.style.cursor = 'pointer';
          node.onclick = () => {
            if (this.yType === 'sentiment') {
              this.yType = 'trend';
            } else if (this.yType === 'trend') {
              this.yType = 'sentiment';
            }
            this.router.navigate([], {
              queryParams: {
                updateAxis: Math.random(),
              },
              queryParamsHandling: 'merge',
              replaceUrl: true,
            });
          };
        }
      });
    });
  }

  setSeries() {
    if (this.routing.isQuery() && this.auth.iquery) {
      this.series = this.event_participants.map((queryParticipants, index) => {
        const color = this.getPaletteColor();
        const queryData = queryParticipants
          .filter((item) => {
            let visibility = true;
            if (this.selectedCountry) {
              if (
                !item.entity.description.Country ||
                item.entity.description.Country.entity.alpha_id !==
                  this.selectedCountry
              ) {
                visibility = false;
              }
            }
            if (this.selectedSector) {
              if (
                !item.entity.description.Sector ||
                item.entity.description.Sector.entity.alpha_id !==
                  this.selectedSector
              ) {
                visibility = false;
              }
            }
            return visibility;
          })
          .map((item) => {
            let xValue = null;
            let yValue = null;
            if (this.xType === 'ratio') {
              xValue = Math.round(item.sentiment.volume_ratio * 100) / 100;
            } else if (this.xType === 'volume') {
              xValue = item.sentiment.count;
            }
            if (this.yType === 'sentiment') {
              yValue = Math.round(item.sentiment.sentiment * 100);
            } else if (this.yType === 'trend') {
              yValue = Math.round(item.sentiment.sentiment_delta * 100);
            }
            return {
              color: color,
              x: xValue,
              y: yValue,
              z: this.bubbleSize,
              type: item.entity.type,
              name: item.entity.name,
              compound_key: item.entity.compound_key,
              country: item.entity.description.Country
                ? {
                    name: item.entity.description.Country.name,
                    value: item.entity.description.Country.entity.alpha_id,
                  }
                : null,
              sector: item.entity.description.Sector
                ? {
                    name: item.entity.description.Sector.name,
                    value: item.entity.description.Sector.entity.alpha_id,
                  }
                : null,
              sentiment: Math.round(item.sentiment.sentiment * 100),
              trend: Math.round(item.sentiment.sentiment_delta * 100),
              volume: item.sentiment.count,
              ratio: Math.round(item.sentiment.volume_ratio * 100) / 100,
            };
          });
        return {
          data: queryData,
          color: color,
          name: this.auth.iquery[index].name,
          sizeByAbsoluteValue: true,
          minSize: this.bubbleSize,
          maxSize: this.bubbleSize,
          events: {
            click: (event) => {
              if (this.routing.isFolio() || this.routing.isHomepage()) {
                const tag =
                  this.activeTag === 'm:' + event.point.options.compound_key
                    ? null
                    : encodeURI('m:' + event.point.options.compound_key);
                this.router.navigate([], {
                  queryParams: {
                    tag: tag,
                  },
                  queryParamsHandling: 'merge',
                  replaceUrl: false,
                });
              } else {
                const participant =
                  this.activeParticipant === event.point.options.compound_key
                    ? null
                    : encodeURI(event.point.options.compound_key);
                this.router.navigate([], {
                  queryParams: {
                    participant: participant,
                  },
                  queryParamsHandling: 'merge',
                  replaceUrl: false,
                });
              }
            },
          },
        };
      });
    } else {
      this.series = this.event_participants.map((item) => {
        const color = this.getPaletteColor();
        let visibility = true;
        if (this.selectedCountry) {
          if (
            !item.entity.description.Country ||
            item.entity.description.Country.entity.alpha_id !==
              this.selectedCountry
          ) {
            visibility = false;
          }
        }
        if (this.selectedSector) {
          if (
            !item.entity.description.Sector ||
            item.entity.description.Sector.entity.alpha_id !==
              this.selectedSector
          ) {
            visibility = false;
          }
        }
        let xValue = null;
        let yValue = null;
        if (this.xType === 'ratio') {
          xValue = Math.round(item.sentiment.volume_ratio * 100) / 100;
        } else if (this.xType === 'volume') {
          xValue = item.sentiment.count;
        }
        if (this.yType === 'sentiment') {
          yValue = Math.round(item.sentiment.sentiment * 100);
        } else if (this.yType === 'trend') {
          yValue = Math.round(item.sentiment.sentiment_delta * 100);
        }
        return {
          data: [
            {
              color: color,
              x: xValue,
              y: yValue,
              z: this.bubbleSize,
              type: item.entity.type,
              name: item.entity.name,
              compound_key: item.entity.compound_key,
              country: item.entity.description.Country
                ? {
                    name: item.entity.description.Country.name,
                    value: item.entity.description.Country.entity.alpha_id,
                  }
                : null,
              sector: item.entity.description.Sector
                ? {
                    name: item.entity.description.Sector.name,
                    value: item.entity.description.Sector.entity.alpha_id,
                  }
                : null,
              sentiment: Math.round(item.sentiment.sentiment * 100),
              trend: Math.round(item.sentiment.sentiment_delta * 100),
              volume: item.sentiment.count,
              ratio: Math.round(item.sentiment.volume_ratio * 100) / 100,
            },
          ],
          visible: visibility,
          color: color,
          name: item.entity.name,
          sizeByAbsoluteValue: true,
          minSize: this.bubbleSize,
          maxSize: this.bubbleSize,
          events: {
            click: (event) => {
              if (this.routing.isFolio() || this.routing.isHomepage()) {
                const tag =
                  this.activeTag === 'm:' + event.point.options.compound_key
                    ? null
                    : encodeURI('m:' + event.point.options.compound_key);
                this.router.navigate([], {
                  queryParams: {
                    tag: tag,
                  },
                  queryParamsHandling: 'merge',
                  replaceUrl: false,
                });
              } else {
                const participant =
                  this.activeParticipant === event.point.options.compound_key
                    ? null
                    : encodeURI(event.point.options.compound_key);
                this.router.navigate([], {
                  queryParams: {
                    participant: participant,
                  },
                  queryParamsHandling: 'merge',
                  replaceUrl: false,
                });
              }
            },
          },
        };
      });
    }
  }

  setExtremes() {
    let xValues = [];
    let yValues = [];
    if (this.routing.isQuery() && this.auth.iquery) {
      this.series.forEach((serie) => {
        serie.data
          .map((item) => {
            return item.x;
          })
          .forEach((element) => {
            xValues.push(element);
          });
        serie.data
          .map((item) => {
            return item.y;
          })
          .forEach((element) => {
            yValues.push(element);
          });
      });
    } else {
      xValues = this.series.map((item) => {
        return item.data[0].x;
      });
      yValues = this.series.map((item) => {
        return item.data[0].y;
      });
    }
    if (this.xType === 'ratio') {
      this.xMin = Math.min(...xValues);
      this.xMax = Math.max(...xValues);
      this.xDivider = (this.xMax + this.xMin) / 2;
    } else if (this.xType === 'volume') {
      this.xMin = Math.min(...xValues);
      this.xMax = Math.max(...xValues);
      this.xDivider = (this.xMax + this.xMin) / 2;
    }
    if (this.yType === 'sentiment') {
      this.yMin = 0;
      this.yMax = 100;
      this.yDivider = 50;
    } else if (this.yType === 'trend') {
      this.yMin = Math.min(...yValues);
      this.yMax = Math.max(...yValues);
      this.yDivider = (this.yMax + this.yMin) / 2;
    }
  }

  getOption() {
    let xTitle = '';
    let yTitle = '';

    if (this.xType === 'ratio') {
      xTitle = 'VOLUME TREND';
    } else if (this.xType === 'volume') {
      xTitle = 'VOLUME';
    }
    if (this.yType === 'sentiment') {
      yTitle = 'SENTIMENT';
    } else if (this.yType === 'trend') {
      yTitle = 'SENTIMENT TREND';
    }

    this.options = {
      chart: {
        backgroundColor: 'transparent',
        type: 'bubble',
        plotBorderWidth: 1,
        plotBorderColor: '#707073',
        zoomType: 'xy',
        panning: true,
        panKey: 'ctrl',
      },

      credits: { enabled: false },

      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            symbol: 'menuball',
            width: 18,
            height: 18,
            symbolX: 9,
            symbolY: 9,
            symbolSize: 10,
            symbolStrokeWidth: 2,
            theme: {
              fill: 'none',
              stroke: 'none',
            },
          },
        },
      },

      title: { text: undefined },

      legend: {
        enabled: !this.routing.isHomepage(),
        maxHeight: 140,
        itemStyle: {
          color:
            localStorage.getItem('theme') === 'light' ||
            this.params.theme === 'light'
              ? '#606063'
              : '#E0E0E3',
        },
        itemHoverStyle: {
          color:
            localStorage.getItem('theme') === 'light' ||
            this.params.theme === 'light'
              ? '#606063'
              : '#E0E0E3',
        },
        itemHiddenStyle: {
          color:
            localStorage.getItem('theme') === 'light' ||
            this.params.theme === 'light'
              ? '#E0E0E3'
              : '#606063',
        },
        navigation: {
          style: {
            color:
              localStorage.getItem('theme') === 'light' ||
              this.params.theme === 'light'
                ? '#606063'
                : '#E0E0E3',
          },
        },
      },

      xAxis: {
        title: {
          text: xTitle,
          style: {
            color: '#A0A0A3',
          },
        },
        labels: {
          format: '{value}',
          style: {
            color: '#A0A0A3',
          },
        },
        gridLineWidth: 1,
        gridLineColor: '#707073',
        lineColor: '#707073',
        minorGridLineColor: '#505053',
        tickColor: '#707073',
        plotLines: [
          {
            color: '#A0A0A3',
            dashStyle: 'dot',
            width: 3,
            value: this.xDivider,
            zIndex: 3,
          },
        ],
      },

      yAxis: {
        min: this.yMin,
        max: this.yMax,
        startOnTick: false,
        endOnTick: false,
        title: {
          text: yTitle,
          style: {
            color: '#A0A0A3',
          },
        },
        labels: {
          format: '{value} %',
          style: {
            color: '#A0A0A3',
          },
        },
        gridLineWidth: 1,
        gridLineColor: '#707073',
        lineColor: '#707073',
        minorGridLineColor: '#505053',
        tickColor: '#707073',
        plotLines: [
          {
            color: '#A0A0A3',
            dashStyle: 'dot',
            width: 3,
            value: this.yDivider,
            zIndex: 3,
          },
        ],
      },

      tooltip: {
        backgroundColor: 'rgba(0,0,0, 0.8)',
        style: {
          color: 'rgba(255,255,255, 1)',
        },
        useHTML: true,
        headerFormat: '<table>',
        pointFormat:
          '<tr><th colspan="2"><h3>{point.name} ({point.type})</h3></th></tr>' +
          '<tr><th>Country:</th><td>{point.country.name}</td></tr>' +
          '<tr><th>Sector:</th><td>{point.sector.name}</td></tr>' +
          '<tr><th><h3></h3></th><td></td></tr>' +
          '<tr><th>Sent:</th><td>{point.sentiment}%</td></tr>' +
          '<tr><th>ΔSent:</th><td>{point.trend}%</td></tr>' +
          '<tr><th>Vol:</th><td>{point.volume}</td></tr>' +
          '<tr><th>ΔVol:</th><td>&#215;{point.ratio}</td></tr>',
        footerFormat: '</table>',
        followPointer: true,
      },

      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            align: 'left',
            verticalAlign: 'top',
            x: 0,
            y: 0,
            format: '{point.name}',
            color:
              localStorage.getItem('theme') === 'light' ||
              this.params.theme === 'light'
                ? '#606063'
                : '#E0E0E3',
            style: {
              // textOutline: 'none',
              fontSize: '11px',
            },
          },
        },
      },

      series: this.series,
    };

    this.chart = new Chart(this.options);
    this.chartRef = this.chart.ref$;
    this.loading = false;
  }
}
