import { PipeTransform, Pipe } from '@angular/core';
import * as d3 from 'd3';
import { RoutingService } from '../service/routing.service';

/**
 * This pipe defines the color of items sorted by sentiment trend
 */
@Pipe({ name: 'colorTrending' })
export class ColorTrendingPipe implements PipeTransform {
  constructor(public routing: RoutingService) {}
  transform(value: any, min: any, max: any): string {
    if (value) {
      if (min > 0) {
        min = 0;
      }
      if (max < 0) {
        max = 0;
      }
      let colors = [];
      if (this.routing.theme === 'light') {
        colors = [
          min !== 0 ? '#a71717' : '#f6d500',
          '#f6d500',
          max !== 0 ? '#009e00' : '#f6d500',
        ];
      } else {
        colors = [
          min !== 0 ? '#dd001a' : '#f6d500',
          '#f6d500',
          max !== 0 ? '#3dac00' : '#f6d500',
        ];
      }
      const colorScale = d3.scaleLinear().domain([min, 0, max]).range(colors);
      return colorScale(value.toFixed(2)).toString();
    } else {
      return 'rgb(120,120,120)';
    }
  }
}
